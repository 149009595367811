<template>
  <div class="my-5">
    <div
      v-for="(group, index) in visiblePlots"
      :key="index"
    >
      <template v-if="group.length">
        <span
          class="my-6 d-inline-block plot-position"
          v-text="`${$t('plot_one')} ${index + 1}`"
        />
        <Plotly
          v-if="group.length"
          :data="setDataPlotly(group)"
          :layout="setLayoutPlotly(group)"
          :displaylogo="false"
          @legendclick="clickLeg($event)"
        />
      </template>
    </div>

    <div class="button-download">
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <button
            v-bind="attrs"
            v-on="on"
            @click="openDownload"
          >
            <v-icon>
              {{ downloadIcon }}
            </v-icon>
          </button>
        </template>
        <span>Download</span>
      </v-tooltip>
    </div>

    <v-dialog
      v-model="downloadDialog"
      max-width="400"
      overlay-opacity="0.5"
    >
      <MSExportPlotData
        :action="measurement"
        :selected-phase="selectedPhase"
        :consolidated-plots="consolidatedPlots"
        @closeDownload="closeDownload"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Plotly } from 'vue-plotly'

import maths from '@/mixins/maths'
import vuexMixin from '@/mixins/vuexMixin'

import { mdiDownload } from '@mdi/js'
import MSExportPlotData from '@/components/Measurements/Forms/MSExtractPlotData.vue'

export default {
  name: 'MSPlotDetails',

  components: {
    Plotly,
    MSExportPlotData
  },

  mixins: [maths, vuexMixin],

  data: () => ({
    slots: ['actions', 'plot', 'draw', 'heatmap'],
    cphase: null,
    expression: null,
    mathExpressions: [],
    mathOperator: null,
    events: [],
    heatmapValue: [],
    feedback: {},
    downloadIcon: mdiDownload,
    downloadDialog: false,
    timeSpan: null,
    consolidatedPlots: [],
    items: ['Plot1', 'Plot2', 'Plot3'],
    selectedItems: [],
    plotAggregation: [],
    selectedPhase: []
  }),

  computed: {
    ...mapState({
      visiblePlots: ({ measurements }) => measurements.visiblePlots,
      plots: ({ Plots }) => Plots
    })
  },

  methods: {
    updatePhase (entry, cphase) {
      const { key, value } = entry

      this.cphase = cphase

      this.setVuexElement({
        path: `Plots.${key}`,
        value: value === 'true' ? 'legendonly' : 'true',
        root: 'Plots'
      })
    },

    clickLeg ({ expandedIndex }) {
      const { phaseA, phaseB, phaseC, phaseN } = this.plots

      const entries = {
        0: ['phaseA', phaseA],
        1: ['phaseB', phaseB],
        2: ['phaseC', phaseC],
        3: ['phaseN', phaseN]
      }

      const entry = entries[expandedIndex]

      this.updatePhase(entry, expandedIndex)
    },

    setDataPlotly (group) {
      return group.map(line => (
        {
          x: line?.data?.time?.map(time =>
            this.formatDate(time)
          ),
          y: line?.data?.value?.map(value =>
            this.roundToThree(value)),
          name: line.aggregation,
          line: { shape: 'hvh' },
          mode: 'lines',
          visible: 'true',
          type: 'scatter'
        }
      ))
    },

    formatDate (time) {
      const date = new Date(time * 1000)
      return date
    },

    setLayoutPlotly (group) {
      const title = group
        .map(({ aggregation }, index) => {
          const notLastPlot = index !== group.length - 1
          return `${aggregation}${notLastPlot ? ' |' : ''}`
        })
        .join(' ')

      return {
        title,
        legend: {
          visible: false,
          orientation: 'h',
          valign: 'top',
          yanchor: 'top',
          bgcolor: 'rgba(0, 0, 0, 0)',
          font: { size: '12', family: 'Faktum Light' }
        },
        margin: { l: 60, r: 20, b: 100, t: 100, pad: 5 },
        xaxis: {
          linecolor: '#fff',
          nticks: 7
        },
        yaxis: { linecolor: '#fff', gridcolor: '#2c2c2c', type: 'linear', autorange: true },
        paper_bgcolor: '#000',
        plot_bgcolor: '#191919',
        font: { color: '#fff', family: 'Faktum Light' },
        hovermode: 'x',
        modebar: { activecolor: '#f4c020', color: '#fff', remove: ['zoomin2d', 'zoomout2d', 'resetscale2d'] }
      }
    },

    openDownload () {
      this.getAggregates()
      this.downloadDialog = true
    },
    closeDownload () {
      this.downloadDialog = false
    },
    getAggregates () {
      this.consolidatedPlots = []
      this.selectedPhase = []

      this.visiblePlots.forEach((aggregates, index) => {
        if (aggregates.length !== 0) {
          const elementIndex = index + 1
          this.selectedPhase.push(`Plot${elementIndex}`)
          for (const element of aggregates) {
            this.consolidatedPlots.push({ Plot: `Plot${elementIndex}`, aggregation: `${element.aggregation}`, time: `${element.data.time}`, value: `${element.data.value}` })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .plot-position {
    font-size: 1.5rem;
  }
</style>

<style>
.plot-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.plotly-container {
  flex: 1;
}

.button-container {
  position: absolute;
  top: 30px;
  right: 10px;
}

.button-download {
  position: absolute;
  top: 45px;
  right: 50px;
}
</style>
